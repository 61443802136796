export const hcpDIRD: any = {
  hcpDIRDMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
  hcpDIRDFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
  hcpFooter: "Footer - HCP - Retina Brazil (PT)",
  hcpExitPopup: "Exit Popup HCP Brazil (PT)",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
  hcpSiteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  hcpDIRDBanner: "Banner - HTML - IRD - HCP - Brazil (PT)",
  hcpAmericalData: "Diagnosing Inherited Retinal Diseases - overlapping presentations - Brazil (PT)",
  hcpAdvancingScience: "Advancing Science HTML - Canada",
  hcpOverlap: "Overlapping presentations of IRD HTML - Canada",
  hcpDIRDFootRef: "Diagnosing Inherited Retinal Diseases HCP - References - Retina Brazil (PT)",
  hcpDIRDVideoCarousel: "DNA Video and Carousel",
  overLapDIRD: "Diagnosing Inherited Retinal Diseases - overlapping presentations New - Brazil (PT)",
  goingBeyondDIRD: "Diagnosing Inherited Retinal Diseases - Going beyond the clinical - Retina Brazil (PT)",
  familyHistoryDIRD: 'Diagnosing Inherited Retinal Diseases - Family history - Retina Brazil (PT)',
  infographicDIRD: "Diagnosing Inherited Retinal Diseases - Infographic - Retina Brazil (PT)",
  earlyTestDIRD: "Diagnosing Inherited Retinal Diseases - Early testing matters - Brazil (PT)",
  caseButtonDIRD: "Diagnosing Inherited Retinal Diseases - Read case study button - Retina Brazil (PT)",
  backToTopGTM: "back to top - inherited retinal disease - hcp",
  footerClassName: "investigating-irds-footer"
}

const size = {
  mobilesmax: '320px'
}

export const deviceLocal = {
  mobilesMax: `(max-width: ${size.mobilesmax})`
}
