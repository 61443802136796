import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const DiagnosingInheritedRetinal = styled.div`
  .o-top-space {
    padding-top: 128px;

    @media ${device.laptopMedium} {
      padding-top: 0;
    }

    .o-container {
      max-width: 1188px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 3rem;
      font-family: 'Gotham', Arial, Helvetica, sans-serif;

      @media ${device.laptopMedium} {
        padding: 0 5rem;
        max-width: 718px;
        font-size: 2rem;
        line-height: 2.6rem;
      }

      @media ${device.midDesktopMax} {
        padding: 0 5rem;
      }

      @media ${device.laptopPros} {
        padding: 0 3rem;
      }

      .c-explore-challenge {
        .o-header--h2 {
          margin-bottom: 0;

          @media ${device.laptopMedium} {
            padding-top: 3rem;
          }
        }

        .c-explore-challenge__heading-caption {
          sup {
            font-size: 60%;
          }
        }

        .c-explore-challenge_image-overview {
          max-width: 1128px;

          sup {
            font-size: 60%;
          }

          .gatsby-image-wrapper {

            @medai ${device.laptopMedium} {
              height: auto;
              min-height: 300px;
              img {
                height: auto;
              }
            }
          }

          .image-caption {
            max-width: 830px;
            margin-left: auto;
            margin-right: auto;
          }

          .image-caption-o-paragraph {
            margin: 40px 0;
            max-width: 970px;
            margin-left: auto;
            margin-right: auto;
            font-size: 3.2rem;
            font-weight: 500 !important;
            line-height: 4.2rem;
            color: #000000;
            margin-top: 61px;

            @media ${device.ipadLandscapemin} and ${device.smallDesktop} {
              font-size: 2.2rem;
              line-height: 3.2rem;
            }

            @media ${device.laptopMedium} {
              font-size: 2rem;
              line-height: 2.6rem;
            }
          }

          .gtm-link-internal {
            border: 1px solid #fff;
            &:after {
              @media ${device.laptopMedium} {
                display: block;
                padding: 10px 0 0;
                height: 24px;
                top: 10px;
                margin: auto;
                left: 6px;
              }
            }
          }
        }
      }

      .o-conatainer {
        padding: 0 !important;
      }

      .o-header--h2 {
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 5rem;
        color: #000000;
        text-align: center;
        letter-spacing: -1.26px;

        @media ${device.laptopMedium} {
          font-size: 2.5rem;
          line-height: 3rem;
          padding-bottom: 40px;
          padding-top: 48px;
        }
      }

      .c-explore-challenge__heading-caption {
        text-align: center;
        font-size: 3.2rem;
        color: #000000;
        padding: 40px 0;
        line-height: 4.2rem;
        margin-bottom: 0;

        @media ${device.laptopMedium} {
          font-size: 2rem;
          line-height: 2.6rem;
          padding: 20px 0;
        }
      }

      .o-tab--section__detail-view__image {
        .gatsby-image-wrapper-constrained {
          display: table;
          margin: 0 auto;
        }
      }

      .c-explore-challenge_image-overview {
        max-width: 950px;
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto 60px auto;
        text-align: center;

        .image-caption {
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          text-align: center;
          font-weight: 500;
          color: #000000;
          margin: 25px 0;
          font-size: 1.6rem;
          line-height: 2.2rem;

          @media ${device.laptopMedium} {
            font-size: 14px !important;
            line-height: 17px !important;
            margin-top: 24px;
            margin-bottom: 24px;
          }
        }

        .heading-paragraph {
          margin: 40px 0;
          text-align: center;
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: #000000;

          @media ${device.laptopMedium} {
            font-size: 2rem;
            line-height: 2.6rem;
          }
        }
        .gtm-link-internal {
          box-shadow: 0 3px 4px #d6d6d6;
          border: 1px solid #fff;
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          text-decoration: none;
          z-index: 1;
          position: relative;
          display: inline-block;
          vertical-align: top;
          overflow: hidden;
          padding: 10px 25px;
          border: none;
          letter-spacing: 0;
          border-radius: 6px;
          background: transparent;
          transition: all 0.3s ease;
          cursor: pointer;
          color: #fff;
          background-color: #8000BE;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.6rem;
          }

          &:hover {
            background: #fff;
            color: #8000BE;
            border-color: #fff;
            font-weight: 700;
            text-decoration: none !important;
          }

          &:after {
            content: '';
            width: 16px;
            height: 14px;
            display: inline-block;
            margin-left: 4px;
            position: relative;
            top: 1px;

            @media ${device.laptopMedium} {
              top: 3px;
            }
          }
        }
        }
      }
      .c-genetic--test {
        margin: 0 70px !important;
        padding: 35px 50px;
        position: relative;
        background-color: #f7f7f7;
        box-shadow: 0 4px 6px #cecece;

        p {
          margin-bottom: 0;
        }

        @media ${device.laptopMedium} {
          margin: 40px 0 48px !important;
          padding: 20px;
        }

        .o-paragraph {
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: #000000;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.6rem;
          }

          span {
            color: #8000BE;
            font-weight: 500;
            font-family: 'Gotham', Arial, Helvetica, sans-serif;
          }
          br {
            @media ${device.laptopMedium} {
              display: none;
            }
          }
        }
        .o-text-link {
          text-transform: unset;
          margin-top: 16px;
          text-decoration: none;
          position: relative;
          display: inline-block;
          color: #000000;
          font-weight: 700;
          padding-bottom: 3px;
          letter-spacing: 0.5px;
          font-size: 2.2rem;
          line-height: 3.2rem;
          font-weight: 500;
          padding-right: 0;

          @media ${device.laptopMedium} {
            padding-top: 0;
            font-size: 1.8rem;
            line-height: 2.6rem;
          }
        }

        a {
          color: #8000BE !important;
          font-family: 'Gotham', Arial, Helvetica, sans-serif;

          &:hover {
            text-decoration: none;
          }
        }

        &:before {
          content: "";
          width: 16px;
          height: 100%;
          display: block;
          background: linear-gradient(-180deg, rgb(128, 0, 190) 0%, rgb(89, 77, 198) 35%, rgb(23, 208, 212) 64%, rgb(0, 255, 217) 100%);
          position: absolute;
          top: 0px;
          right: 100%;
          box-shadow: rgb(206 206 206) 0px 4px 6px;

          @media ${device.laptopMedium} {
            content: "";
            width: 100%;
            height: 10px;
            background: linear-gradient(-188deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
            top: 0;
            left: 0;
            right: unset;
            box-shadow: none;
          }
        }
      }
      .accordion-section {
        padding-top: 100px;
        padding-bottom: 0px;

        @media ${device.laptopMedium} {
          padding-top: 0;
          padding-bottom: 0px;
        }

        .o-container {
          @media ${device.laptopMedium} {
            padding-left: 0;
            padding-right: 0;
          }
        }

        h3 {
          text-align: center;
          color: rgb(0, 0, 0);
          max-width: 765px;
          margin: auto;
          padding-bottom: 86px;

          @media ${device.laptopMedium} {
            font-size: 2.5rem;
            line-height: 3rem;
            font-weight: 600;
          }
        }

        p {
          text-align: center;
          color: rgb(0, 0, 0);
          max-width: 820px;
          margin: auto;
          font-weight: 400;
        }

        p.inherited-disclaimer {
          font-size: 2.2rem;
          line-height: 3.2rem;
          max-width: 100%;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          padding-top: 76px;
        }
        .accordion-link-text {
          text-align: right;
          max-width: 100%;
          margin: auto;

          @media ${device.laptopMedium} {
            text-align: center;
            padding: 15px 20px;
          }

          a {
            color: #8000BE;
            font-weight: 700;
            padding-right: 32px;
            font-size: 2.2rem;
            line-height: 3.2rem;

            &:last-child {
              padding-right: 0px;
              text-decoration: none;
              color: #000000;

              &:hover {
                color: #8000BE;
                text-decoration: underline !important;
                text-underline-offset: 3px;
                transition: all 0.3s ease;
              }
            }

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
              line-height: 2.6rem;
            }

            &.expand-active {
              text-decoration: underline;
              text-underline-offset: 3px;
              transition: all 0.3s ease 0s;
              color: #000;
            }
          }
        }

        .panel-group {
          sup {
            font-size: 60%;
          }
          .panel {
            border-radius: 0px;
            margin: 20px 0px;
            border: 1px solid rgb(128, 0, 190);
            box-shadow: rgb(206 206 206) 0px 4px 6px;

            .panel-heading {
              padding: 0px;
              border-radius: 0px;
              color: rgb(33, 33, 33);
              background-color: rgb(250, 250, 250);
              border-color: rgb(238, 238, 238);

              .panel-title {
                display: flex;
                align-items: center;
                position: relative;
                padding: 30px 32px 30px 27px;
                margin: 0px;

                @media ${device.laptopMedium} {
                  padding-left: 16px;
                }

                .accordion-icon {
                  width: 37px;
                  height: 37px;
                  margin-right: 20px;
                  font-size: 42px;
                  border-color: transparent;
                  background: transparent;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                  color: rgb(128, 0, 190);

                  @media ${device.minimumDeskEnd} {
                    font-size: 35px;
                    margin-right: 0;
                    width: auto;
                    height: auto;
                    display: block;
                  }

                  &:before {
                    content: " ";
                    height: 22px;
                    width: 28px;
                    margin-left: -12px;
                    display: block;
                    background-size: 20px !important;

                    @media ${device.ipadLandscapemin} {
                      background-size: 24px !important;
                      height: 26px;
                    }

                    @media ${device.desktopmenu} {
                      margin-left: unset;
                    }
                  }
                }

                .scientific {
                  color: rgb(128, 0, 190);
                  line-height: 3.4rem;
                  font-size: 27px;
                  text-decoration: none;
                  font-family:'Gotham', Arial, Helvetica, sans-serif;

                  @media ${device.laptopMedium} {
                    line-height: 2.6rem;
                    font-size: 2rem;
                    display: block;
                    word-break: break-word;
                    width: 78%;
                    font-family:'Gotham', Arial, Helvetica, sans-serif;
                    font-weight: 700;

                  }

                  @media ${device.mobile360max} {
                    width: 100%;
                  }


                  .subset-text {
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                    display: block;
                    margin-top: 6px;
                    font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
                  }
                }
                .o-text-title {
                  width: 100%;
                  margin-bottom: 0;

                  @media ${device.laptopMedium} {
                    font-size: 20px;
                    line-height: 2.6rem;
                    width: 60%;
                    max-width: 276px;
                  }

                  @media ${device.tabletMax} {
                    width: 65%;
                  }

                  @media ${device.laptopPros} {
                    display: block;
                    max-width: 230px;
                  }

                  @media ${device.mobile360max} {
                    display: block;
                    max-width: 185px;
                  }
                }

                .glyphicon {
                  position: absolute;
                  right: 15px;
                  font-size: 0;
                  width: 35px;
                  height: 35px;
                  background-color: rgb(128,0,190);
                  border-radius: 50%;
                  box-shadow: rgb(0 0 0 / 40%) 0px 4px 6px;

                  @media ${device.laptopMedium} {
                    right: 12px;
                    width: 40px;
                    height: 40px;
                  }
                }
                .glyphicon-plus {
                  &:before {
                    content: ' ';
                    height: 35px;
                    width: 35px;
                    display: block;
                    background-size: 35px;

                    @media ${device.laptopMedium} {
                      background-size: 40px;
                      width: 40px;
                      height: 40px;
                    }
                  }
                }
                .glyphicon-minus {
                  &:before {
                    content: ' ';
                    height: 35px;
                    width: 35px;
                    display: block;
                    background-size: 35px;

                    @media ${device.laptopMedium} {
                      background-size: 40px;
                      width: 40px;
                      height: 40px;
                    }
                  }
                }
              }
            }
            .panel-body {
              background-color: rgb(247, 247, 247);
              padding-left: 30px;
              padding-bottom: 20px;
              padding-right: 30px;

              @media ${device.laptopMedium} {
                padding-left: 0;
              }

              .o-paragraph {
                color: rgb(48, 0, 100);
                text-align: left;
                max-width: 820px;
                margin: auto;
                font-weight: 400;
                font-size: 2.2rem;
                line-height: 3.2rem;
                padding: 10px 0 0 27px;
                word-break: break-word;

                @media ${device.laptopMedium} {
                  padding-top: 20px;
                  font-size: 1.8rem !important;
                  line-height: 2.6rem !important;
                  padding-left: 30px;
                }
              }
              ul {
                margin: 0px;
                width: 100%;
                text-align: left;
                padding: 0;
                display: table;
                list-style: none;
                padding: 0 0 0 30px;

                @media ${device.ipadLandscapemin} {
                  width: 100%;
                }

                @media ${device.laptopMedium} {
                  padding: 0 30px 10px;
                  padding-right: 0;
                  padding-bottom: 0;
                }

                li {
                  display: block;
                  padding: 8px 0px 8px 25px;
                  color: rgb(0, 0, 0);
                  font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
                  text-align: left;
                  font-size: 2.2rem;
                  font-weight: 500;
                  line-height: 3.2rem;
                  position: relative;

                  &:before {
                    top: 22px;
                    content: "";
                    position: absolute;
                    background-color: rgb(128, 0, 190);
                    width: 8px;
                    height: 8px;
                    margin: auto;
                    left: 0px;
                    @media ${device.laptopMedium} {
                      top: 30px;
                    }
                  }

                  &:nth-child(2) {
                    @media ${device.ipadair} and ${device.minimumDeskEnd} {
                      margin-right: 5px;
                    }
                  }
                }
              }
              .subset-wrapper {
                width: 100%;
                padding-left: 60px;

                @media ${device.laptopMedium} {
                  width: 100%;
                  padding-left: 30px;
                }

                .subset-heading {
                  margin-left: 0;
                  font-weight: 400;
                  font-size: 2.2rem;
                  line-height: 3.2rem;
                  color: rgb(48, 0, 100);
                  padding-bottom: 10px;
                  text-align: left;
                }

                .subset-para span {
                  font-weight: 700;
                }

                .subset-para {
                  font-size: 2.2rem;
                  font-weight: 500;
                  line-height: normal;
                  font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
                  text-align: left;
                  padding-bottom: 10px;
                  margin-left: 0;

                  @media ${device.laptopMedium} {
                    padding-right: 30px;
                    font-size: 1.8rem;
                  }
                }
              }

              .listing-main {
                display: flex;
                background-color: #f7f7f7;

                @media ${device.laptopMedium} {
                  display: block;
                  padding-left: 0;
                }

                .o-columns--four {
                  width: 25%;

                  @media ${device.laptopMedium} {
                    width: 100%;
                  }

                  &.no-bullet {

                    @media ${device.laptopMedium} {
                      padding: 0;
                    }

                    .listing-section {
                      li {
                        list-style-type: none;
                        padding: 0;

                        &:before {
                          display: none;
                        }
                      }
                    }
                  }

                  &.subset-wrapper {
                    padding-right: 20px;

                    @media ${device.laptopMedium} {
                      padding: 0 30px;
                    }

                    .subset-heading {
                      margin: auto;
                      font-weight: 400;
                      font-size: 2.2rem;
                      line-height: 3.2rem;
                      color: rgb(48,0,100);
                      padding-bottom: 10px;

                      @media ${device.laptopMedium} {
                        font-size: 1.8rem;
                        line-height: 2.6rem;
                        text-align: left;
                      }
                    }

                    .subset-para {
                      font-size: 1.8rem;
                      font-weight: 500;
                      line-height: normal;
                      font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
                      text-align: left;
                      padding-bottom: 10px;

                      @media ${device.laptopMedium} {
                        font-size: 1.8rem;
                        line-height: 2.6rem;
                      }

                      span {
                        font-weight: 700;
                      }
                    }
                  }
                }

                .o-columns--three {
                  width: 33.33%;

                  @media ${device.laptopMedium} {
                    width: 100%;
                  }

                  &.no-bullet {

                    @media ${device.laptopMedium} {
                      padding: 0;
                    }

                    .listing-section {
                      @media ${device.ipadLandscapemin} {
                        padding-top: 10px;
                        padding-bottom: 10px;
                      }

                      @media ${device.laptopMedium} {
                        padding-right: 0;
                        padding-bottom: 10px;
                      }

                      li {
                        list-style-type: none;
                        padding: 0;

                        @media ${device.laptopMedium} {
                          padding-top: 10px;
                        }

                        &:before {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
              .full-width-tab-container {
                padding-top: 28px;
                h5 {
                  margin: 0;
                }
                p {
                  text-align: left;
                  padding: 28px 0 0 30px;
                  margin: 0;
                  font-size: 22px;
                  line-height: 32px;
                  font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
                  max-width: 100%;

                  @media ${device.laptopMedium} {
                    font-size: 1.8rem !important;
                    line-height: 2.6rem !important;
                  }

                  br {
                    content:"";
                    padding: 12.5px;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
      .symptones-listing {
        padding: 120px 80px;
        padding-top: 84px;

        &>.row {
          margin-right: -15px;
          margin-left: -15px;
        }

        .col-lg-6 {
          padding-right: 15px;
          padding-left: 15px;

          .listing-section {
            padding: 0 0;
          }
        }

        sup {
          font-size: 60%;
        }

        @media ${device.laptopMedium} {
          padding: 38px 20px 48px;
        }

        .col-lg-6 {
          &:nth-child(2) {
            @media ${device.laptopMedium} {
              margin-top: -12px;
            }
          }
        }

        h2 {
          color: #000000;
          text-align: center;
          max-width: 620px;
          margin: auto;
          font-size: 4.2rem;
          font-weight: 600;
          line-height: 5rem;
        }
        .o-paragraph {
          color: #000000;
          text-align: center;
          margin: auto;
          padding-top: 40px;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          max-width: 750px;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.6rem;
            padding-top: 20px;
          }
        }

        .listing-section {
          list-style: none;
          margin: auto;
          display: table;
          margin-top: 40px;
          display: block;
          padding: 8px 0 8px 25px;
          color: #000000;
          font-family:'Gotham-Book', Arial, Helvetica, sans-serif;

          @media ${device.laptopMedium} {
            margin-top: 10px !important;
            padding-left: 0;
          }

          li {
            padding: 0;
            padding-left: 30px;
            width: 100%;
            margin: 13px 0;
            position: relative;
            text-align: left;
            display: inline-block;

            @media ${device.laptopMedium} {
              font-size: 1.8rem !important;
              line-height: 2.6rem !important;
            }

            &:before {
              content: "";
              position: absolute;
              background-color: #8000BE;
              width: 8px;
              height: 8px;
              top: 13px;
              margin: auto;
              left: 0;

              @media ${device.laptopMedium} {
                top: 8px;
              }
            }
          }
        }
      }

      .inherited-retinal-disease-c-family {
        position: relative;
        &:before {
          content: "";
          background: #00FFD9;
          display: block;
          width: 100%;
          height: 70%;
          position: absolute;
          top: 15%;

          @media ${device.laptopMedium} {
            top: 5%;
            height: 92%;
          }
        }
      }
      .c-family--story__inner {
        background-color: #f7f7f7;
        box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
        position: relative;
        padding: 10rem 7rem;
        color: #000000;

        @media ${device.laptopMedium} {
          padding: 2rem 3rem;
          padding-bottom: 32px;
        }

        &:before {
          content: "";
          background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
          width: 100%;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .o-container {
          padding: 0 0;

          .o-inner {
            padding: 0;
          }
        }

        .o-header--h2 {
          margin: 0 0 40px;
          font-size: 4.2rem;
          font-weight: 600;
          line-height: 5rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            text-align: center;
            margin: 30px 0 30px;
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }

        .o-block--center__small {
          max-width: 850px;
          margin-left: auto;
          margin-right: auto;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          text-align: center;
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: #000000;
          margin-bottom: 0;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.6rem;
          }

          br {
            @media ${device.laptopMedium} {
              display: none;
            }
          }

          sup {
            font-size: 60%;
          }
        }
        .genetic-cause {
          padding: 30px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          @media ${device.laptopMedium} {
            padding-bottom: 20px;
            display: block;
            padding-top: 0;
          }

          sup {
            font-size: 60%;
          }

          .genetic-cause__left-img-secion {

            @media ${device.laptopMedium} {
              position: relative;
            }

            .inupto {
              font-size: 2.2rem;
            }
            .circle_percent {
              .percentblock {
                font-size: 22px;
              }

              .percent_text {
                line-height: 40px;
                font-size: 50px;
              }

              small {
                top: 7px;
                font-size: 80%;
                font-weight: 400;
              }
            }
            .CircularProgressbar {
              .CircularProgressbar-text {
                font-size: 3.2rem !important;

              }
            }
            small {
              left: 82px;
            }
          }

          .genetic-cause__right-text-secion {
            padding-left: 32px;

            @media ${device.laptopMedium} {
              text-align: center;
              font-size: 2rem;
              line-height: 2.6rem;
              width: Calc(100% - 37px);
              margin: auto;
              position: relative;
              padding-left: 0;
              padding-bottom: 20px;
            }

            p {
              font-size: 2.7rem;
              line-height: 3.4rem;
              color: #000000;
              font-family: 'Gotham', Arial, Helvetica, sans-serif;
              margin-bottom: 0;

              @media ${device.laptopMedium} {
                text-align: center;
                font-size: 2rem;
                line-height: 2.6rem;
              }

              strong {
                color: #8000BE;
                font-weight: 700;
              }

              span {
                color: #8000BE !important;
                font-weight: 700;
              }
            }
          }
        }

        .o-text-center {
          text-align: center;

        }
        .case-study-btn {
          text-align: center;

          a.gtm-link-internal {
            box-shadow: 0 3px 4px #d6d6d6;
            background: #8000BE !important;
            color: #fff;
            border: 1px solid #fff;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            text-decoration: none;
            z-index: 1;
            position: relative;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            padding: 10px 25px;
            letter-spacing: 0;
            border-radius: 6px;
            background: transparent;
            transition: all 0.3s ease;
            cursor: pointer;

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
              line-height: 2.6rem;
              padding-bottom: 17px;
            }

            @media ${device.laptopPros} {
              padding-bottom: 17px;
            }

            &:after {
              content: '';
              width: 16px;
              height: 14px;
              display: inline-block;
              margin-left: 4px;
              top: 2px;
              position: relative;

              @media ${device.laptopMedium} {
                top: 8px;
                display: block;
                margin: auto;
              }

              @media ${device.laptopPros} {
                top: 8px;
              }
            }

            &:hover {
              background: #fff !important;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              box-shadow: 0 3px 4px #d6d6d6;
              text-decoration: none !important;
            }
          }
        }

        ul {
          padding: 40px 0;
          list-style: none;
          margin: auto;
          color: #000000;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          padding-left: 45px;

          li {
            padding-left: 30px;
            width: 100%;
            margin: 13px 0;
            position: relative;
            text-align: left;
            display: inline-block;
            font-size: 2.2rem;
            line-height: 3.2rem;
            font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

            &:before {
              content: "";
              position: absolute;
              background-color: #8000BE;
              width: 8px;
              height: 8px;
              top: 13px;
              margin: auto;
              left: 0;
            }
          }
        }

      }
      .image-points-section {
        padding-bottom: 88px;

        @media ${device.laptopMedium} {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          margin-top: 48px;
          margin-bottom: 48px;
        }

        .o-container {
          @media ${device.laptopMedium} {
            max-width: 718px;
            padding: 0 5rem;
            font-size: 2rem;
            line-height: 2.6rem;
          }

          @media ${device.laptopPros} {
            padding: 0 3rem;
          }
        }

        .o-internal--h2 {
          width: 100%;
          margin: auto;
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            text-align: center;
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
        .listing-btm-section {
          padding: 50px 0 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          @media ${device.laptopMedium} {
            display: block;
            padding: 20px 0 0;
            width: 100%;
          }

          sup {
            font-size: 60%;
          }

          .o-paragraph {
            width: 50%;
            float: left;
            font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            color: #000000;
            margin-bottom: 0;

            @media ${device.laptopMedium} {
              width: 100%;
              float: none;
              margin-top: 0;
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.6rem;
            }
          }

          .left-img {
            position: absolute;
            right: 0;
            left: initial;
            width: 48.4%;
            margin-top: -36px;

            @media ${device.laptopMedium} {
              position: relative;
              margin: 0;
              margin-top: 30px;
              width: 100%;
            }
          }
        }
      }

      .large-card-section {
        background-color: #8000BE;
        padding: 88px 80px 44px 80px;

        @media ${device.laptopMedium} {
          padding: 30px 30px 48px;
          width: 100%;
        }

        sup {
          font-size: 60%;
        }

        h2 {
          color: #fff;
          text-align: center;
          padding-bottom: 55px;
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          margin-bottom: 0;

          @media ${device.laptopMedium} {
            padding-bottom: 20px;
            font-size: 2.5rem;
            line-height: 3rem;
          }

          br {
            @media ${device.laptopMedium} {
              display: none;
            }
          }
        }

        .image-block {
          display: flex;

          @media ${device.laptopMedium} {
            display: block;
            font-size: 2rem;
            line-height: 2.6rem;
          }

          .o-columns--two {
            width: 100%;
            padding-left: 0;
            margin-bottom: 0;

            @media ${device.laptop} {
              width: 50%;
            }
          }
          span {
            strong {
              font-weight: 700;
            }
          }
        }

        ul {

          @media ${device.laptopMedium} {
            padding-left: 0;
          }

          li {
            color: #fff;
            padding: 0 30px 28px 42px;
            position: relative;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            list-style-type: none;
            font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

            @media ${device.laptopMedium} {
              padding: 0 30px 15px 42px;
              font-size: 1.8rem;
              line-height: 2.6rem;
            }

            &:before {
              content: "";
              background-color: #fff;
              width: 8px;
              height: 8px;
              position: absolute;
              top: 10px;
              left: 15px;
            }

            span {
              font-weight: 700;
              font-family: 'Gotham', Arial, Helvetica, sans-serif;
              color: #fff;

              @media ${device.laptopMedium} {
                font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
              }
            }
          }
        }
        .suspect-paragraph {
          color: #fff;
          text-align: center;
          padding-top: 10px;
          font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;

          @media ${device.laptopMedium} {
            padding-top: 20px;
            font-size: 1.8rem;
            line-height: 2.6rem;
          }
        }
      }
      .footer-block {
        background-color: #8000BE;
        padding: 0 100px;
        display: flex;

        @media ${device.laptopMedium} {
          padding: 0 46px;
          display: block;
          margin-top: -1px;
        }

        @media ${device.laptopPros} {
          padding: 0 46px;
          display: block;
          margin-top: -1px;
        }

        .footer-block--row {
          margin: auto;
          display: flex;

          @media ${device.laptopMedium} {
            display: block;
          }

          .footer-inner-block {
            border-radius: 10px;
            background-color: #fff;
            padding: 25px 20px 120px;
            position: relative;
            margin: 0 15px;
            z-index: 992;
            font-size: 2.2rem;
            line-height: 3.2rem;

            @media ${device.laptopMedium} {
              padding-bottom: 30px !important;
              width: 100%;
              margin: 30px auto 0;
              padding: 16px 20px 120px;
            }

            &:first-child {
              margin-left: 0;

              @media ${device.laptopMedium} {
                margin-top: 0;
                margin-left: auto;
              }

              a {
                padding: 10px 60px !important;

                @media ${device.laptopMedium} {
                  padding: 10px !important;
                  display: block !important;
                }

                @media ${device.minimumDeskEnd} {
                  padding: 10px 18px !important;
                }

                @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                  padding: 10px 8px !important;
                }

                &:after {
                  width: 26px !important;

                  @media ${device.laptopMedium} {
                    width: 16px !important;
                  }

                  @media ${device.ipadair} and ${device.minimumDeskEnd} {
                    width: 34px !important;
                  }
                }
              }
            }
            &:last-child {
              margin-right: 0;

              @media ${device.laptopMedium} {
                margin-left: auto;
                margin-right: auto;
              }
              a {
                padding: 10px 45px !important;

                @media ${device.laptopMedium} {
                  padding: 10px !important;
                  display: block !important;
                }

                @media ${device.minimumDeskEnd} {
                  padding: 10px 18px !important;
                }

                @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                  padding: 10px 8px !important;
                }

                &:after {
                  width: 27px !important;

                  @media ${device.laptopMedium} {
                    width: 16px !important;
                  }

                  @media ${device.ipadair} and ${device.minimumDeskEnd} {
                    width: 40px !important;
                  }

                }
              }
            }

            &:before {
              content: "";
              background: #00FFD9;
              width: 100%;
              height: 16px;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 9px 9px 0 0;
            }

            img {
              margin: auto;
              text-align: center;
              display: block;
              padding: 10px 0 20px 0;
              height: 100px;
              width: auto;

              @media ${device.laptopMedium} {
                padding: 16px 0 16px 0;
                height: auto;
              }
            }
            img.footer-inner-block__small-icon {
              display: none !important;
            }

            .gatsby-image-wrapper-constrained {
              margin: 0 auto;
              display: table;
            }

            .o-common-title {
              font-weight: 500;
              min-height: 76px;
              font-size: 2.7rem;
              line-height: 3.8rem;
              color: #8000BE;
              text-align: center;

              @media ${device.laptopMedium} {
                min-height: unset;
                font-size: 2rem;
                line-height: 2.2rem;
              }

            }
            .o-paragraph {
              text-align: center;
              padding: 24px 0 0 0;
              font-size: 2.2rem;
              font-weight: 500;
              line-height: 3.2rem;
              color: #000000;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

              @media ${device.laptopMedium} {
                font-size: 1.8rem;
                line-height: 2.6rem;
              }

              @media ${device.ipadair} and ${device.minimumDeskEnd} {
                padding: 24px 0 20px 0 !important;
              }

            }
            .gtm-link-internal {
              position: absolute;
              width: 410px;
              bottom: 25px;
              text-align: center;
              box-shadow: 0 3px 4px #d6d6d6;
              display: flex !important;
              justify-content: center;
              align-items: center;
              font-size: 2.2rem;
              background: #8000BE;
              color: #fff;
              border: 1px solid #fff;
              text-decoration: none;
              min-height: 62px;
              font-weight: 500;
              line-height: 3.2rem;
              text-decoration: none;
              z-index: 1;
              letter-spacing: 0;
              border-radius: 6px;
              padding-left: 20px;

              @media ${device.laptopMedium} {
                position: relative;
                left: 0;
                bottom: 0;
                margin-top: 32px;
                width: 100%;
                display: block;
                padding: 10px;
                font-size: 18px;
              }

              @media ${device.midDesktopMax} {
                width: 95%;
                left: 2.5%;
              }

              @media ${device.ipadair} and ${device.minimumDeskEnd} {
                width: 75%;
                left:12.5%;
              }

              &:after {
                content: '';
                width: 20px;
                height: 16px;
                display: inline-block;
                margin-left: 4px;
              }

              &:hover {
                background: #fff !important;
                color: #8000BE;
                border-color: #fff;
                font-weight: 700;
                box-shadow: 0 3px 4px #d6d6d6;
                text-decoration: none !important;
              }
            }
          }
          .o-columns--two {
            width: 100%;

            @media ${device.laptop} {
              width: 50%;
            }
          }
        }
      }
    }
  }
  /* div.footercontainer {
    footer {
      >.row {
        @media ${device.mobileMedium} {
          padding: 9.8rem 0 0 0 !important;
        }
      }
    }
  } */
`;
